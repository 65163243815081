import React, { Component } from "react";
import lodash from "lodash";
import Api from "../../../shared/networking/Api";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import SubscriptionPackage from "../component/SubscriptionPackage";
import PaymentOptions from "../../../shared/components/PaymentOptions";
import Account from "../../account/Account";
import SubscriptionProcessLayout from "./SubscriptionProcessLayout";

class SubscriptionPackagesV2 extends Component {
  // TODO: Handle payment options dialog
  // TODO: handle survey
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showPaymentOptionForItem: null,
      showPaymentOptionForIndex: null,
      survey: null,
      showSurvey: false,
      voucherCode: null,
      showLogin: false,
      campaignDialogDismissed: false,
      redirectedToPurchaseItemId: null,
      isDarkMode: false,
    };
  }

  componentDidMount() {
    this.getPackages();

    Api().appService.polls({
      success: (polls) => {
        if (lodash.isArray(polls) && polls.length > 0) {
          this.setState({
            survey: polls[0],
          });
        }
      },
      error: () => {
        // Do nothing
      },
    });

    if (this.props.location && this.props.location.state) {
      let hideEnterprise = this.props.location.state.hideEnterprise;
      let hideAdhoc = this.props.location.state.hideAdhoc;
      let showCampaignDialog = this.props.location.state.showCampaignDialog;
      let redirectedAppId = this.props.location.state.redirectedAppId;
      let redirectedToPurchaseItemId =
        this.props.location.state.redirectedToPurchaseItemId;

      this.setState(
        {
          hideEnterprise,
          hideAdhoc,
          showCampaignDialog,
          redirectedAppId,
          redirectedToPurchaseItemId,
        },
        () => {
          if (redirectedToPurchaseItemId) {
            this.getPackages();
          }
        },
      );
    }
  }

  getPackages = () => {
    const { redirectedToPurchaseItemId } = this.state;

    Api().subscriptionService.subscriptionList({
      success: (packages) => {
        this.setState(
          {
            packages,
            loading: false,
          },
          () => {
            if (redirectedToPurchaseItemId) {
              for (let i = 0; i < packages.length; i++) {
                const p = packages[i];
                if (p.id === redirectedToPurchaseItemId) {
                  this.packageClicked(p);
                }
              }
            }
          },
        );
      },
      error: () => {
        // TODO: handle error
        // TODO: add try again
      },
    });
  };

  packageClicked = (item, index) => {
    const { redirectedToPurchaseItemId } = this.state;
    let checkUserAuthentication = true;
    if (this.props.location && this.props.location.state) {
      checkUserAuthentication =
        this.props.location.state.checkUserAuthentication;
    }

    if (checkUserAuthentication) {
      Api().authService.currentAccount({
        success: () => {
          this.setState({
            showPaymentOptionForItem: item,
            showPaymentOptionForIndex: index,
          });
        },
        error: (code) => {
          if (code === 401) {
            this.setState(
              {
                showLogin: true,
              },
              () => {
                if (redirectedToPurchaseItemId) {
                  this.setState({
                    showPaymentOptionForItem: item,
                    showPaymentOptionForIndex: index,
                  });
                }
              },
            );
          } else {
            this.setState({
              showPaymentOptionForItem: item,
              showPaymentOptionForIndex: index,
            });
          }
        },
      });
    } else {
      this.setState({
        showPaymentOptionForItem: item,
        showPaymentOptionForIndex: index,
      });
    }
  };

  onBackButtonClicked = (e) => {
    e.preventDefault();

    const { history } = this.props;
    history.goBack();
  };

  closePaymentOptions = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      showPaymentOptionForItem: null,
    });
  };

  applyVoucherCode = (code) => {
    this.setState(
      {
        voucherCode: code,
        showSurvey: false,
        survey: null,
      },
      () => {
        this.getVoucherCodeInfo();
      },
    );
  };

  getVoucherCodeInfo = () => {
    const { voucherCode, packages } = this.state;
    let { newPrices } = this.state;
    if (packages) {
      packages.forEach((p) => {
        Api().ordersService.discountCodeDetails(p.id, voucherCode, {
          success: (response) => {
            if (!newPrices) {
              newPrices = {};
            }
            newPrices[p.id] = response.fixedAmount
              ? p.price - response.discountAmount
              : p.price - (p.price * response.discountAmount) / 100;
            this.setState({
              newPrices,
            });
          },
          error: () => {
            // TODO: handle error
          },
        });
      });
    }
  };

  render() {
    const {
      packages,
      loading,
      newPrices,
      showPaymentOptionForItem,
      showPaymentOptionForIndex,
      voucherCode,
      showLogin,
      campaignDialogDismissed,
      showCampaignDialog,
      redirectedAppId,
    } = this.state;

    if (loading) {
      return <LoadingSpinner linear />;
    }
    return (
      <div className="packages-container">
        <div
          className="header back-button"
          style={{
            position: "fixed",
            top: "0",
            height: "40px",
            zIndex: "11",
          }}
        >
          <a
            href="#"
            className="back-button"
            onClick={(e) => {
              e.preventDefault();
              this.onBackButtonClicked();
            }}
          >
            {" "}
          </a>
        </div>
        <SubscriptionProcessLayout>
          <div className="subscription-packages v2">
            {loading ? (
              <LoadingSpinner linear />
            ) : (
              <div className="packages-list">
                <h1>خرید اشتراک</h1>
                <p style={{ fontSize: 14, marginTop: 3 }}>
                  تمامی بسته ها دارای گارانتی فعال سازی مجدد می‌باشند.
                </p>
                {packages
                  ? packages.map((item, index) => {
                      if (item.id === 11) {
                        if (
                          redirectedAppId &&
                          ![
                            52940120, 161341142, 589353554, 720708330,
                            218159182, 601424969, 601424969,
                          ].includes(redirectedAppId)
                        ) {
                          return "";
                        }
                      }

                      return (
                        <SubscriptionPackage
                          colorType={item.colorType}
                          priceAfterVoucherApplied={
                            newPrices ? newPrices[item.id] : null
                          }
                          item={item}
                          index={index}
                          onClick={() => {
                            this.packageClicked(item, index);
                          }}
                          key={item.id}
                        />
                      );
                    })
                  : ""}
                <p className="general-message">
                  به قیمت نهایی تمام بسته ها ۱۰٪ مالیات بر ارزش افزوده اضافه
                  خواهد شد.
                </p>
              </div>
            )}
            {showPaymentOptionForItem ? (
              <a
                href="#"
                className="close-payment-options-button"
                onClick={this.closePaymentOptions}
              >
                <div className="payment-options-container v2">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <PaymentOptions
                      voucher={voucherCode}
                      subscriptionPackage={showPaymentOptionForItem}
                      onDone={this.closePaymentOptions}
                      v2={true}
                      packageIndex={showPaymentOptionForIndex}
                      onUserIsNotAuthenticated={() => {
                        this.setState({ showLogin: true });
                      }}
                    />
                  </a>
                </div>
              </a>
            ) : (
              ""
            )}
            {showLogin ? (
              <div className="overlay">
                <Account
                  isOverlay
                  didLogin={() => {
                    this.setState({ showLogin: false });
                  }}
                  didPressCloseButton={() => {
                    this.setState({ showLogin: false });
                  }}
                />
              </div>
            ) : (
              ""
            )}
            {/* {showCampaignDialog === true && !campaignDialogDismissed ? (
              <div className="campaign-dialog">
                <CampaignDialog
                  onSkip={() => {
                    this.setState({
                      campaignDialogDismissed: true,
                    });
                  }}
                  dialogTitle=""
                  hasPrimaryButton={false}
                  dialogText="با وارد کردن کد دریافتی از باشگاه رانندگان اسنپ، تنها با قیمت 125 هزار تومان اشتراک ۳ ماهه ادهاک را دریافت کنید"
                  dialogBannerImage="/images/snapp-drivers2.jpg"
                  cancelButtonTitle="متوجه شدم"
                />
              </div>
            ) : (
              ""
            )} */}
          </div>
        </SubscriptionProcessLayout>
      </div>
    );
  }
}

export default SubscriptionPackagesV2;
