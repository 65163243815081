import React, { Component } from "react";
import { Line as LineProgressBar } from "rc-progress";
import utf8 from "utf8";
import classNames from "classnames";
import base64 from "base-64";
import { PulseLoader } from "react-spinners";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import Api from "../networking/Api";
import { Button } from "reactstrap";
import { toEnglishDigits } from "../StringUtils";

const reward = {
  16: {
    logo: "filimo-logo.svg",
    title: "به همراه اشتراک یک ماهه فیلیمو",
  },
  17: {
    logo: "taaghche-logo.svg",
    title: "به همراه اشتراک یک ماهه طاقچه",
  },
};

const defaultGatewayId = 4;
const gateways = [
  {
    id: 4,
    logo: "/images/logo-parsian.png",
    title: "درگاه پارسیان",
    description: null,
  },
  // {
  //   id: 5,
  //   logo: "/images/logo-asan-pardakht.png",
  //   title: "درگاه آسان پرداخت",
  //   description: null,
  // },
  // {
  //   id: 7,
  //   logo: '/images/logo-snapp-pay.png',
  //   title: 'اسنپ پی',
  //   description: 'پرداخت در ۴ قسط بدون کارمزد',
  // }
];

class PaymentOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      price: props.subscriptionPackage ? props.subscriptionPackage.price : 0,
      discountAmount: 0,
      fixedAmount: false,
      showDiscountField: false,
      discountLoading: false,
      purchaseLoading: false,
      discountCode: props.voucher || "",
      discountError: null,
      discountCodeApplied: false,
      gatewayRedirect: null,
      gatewayId: defaultGatewayId,
    };
  }

  componentDidMount() {
    disableBodyScroll(document.getElementById("payment-options"));
    if (this.props.voucher) {
      this.applyDiscount();
      this.showDiscountField();
    }
  }

  componentWillUnmount() {
    enableBodyScroll(document.getElementById("payment-options"));
  }

  showDiscountField = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      showDiscountField: true,
    });
  };

  applyDiscount = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { discountCode, discountError } = this.state;
    const { subscriptionPackage } = this.props;

    this.setState({
      discountLoading: true,
    });

    Api().ordersService.discountCodeDetails(
      subscriptionPackage.id,
      discountCode,
      {
        success: (details) => {
          if (!details.status) {
            if (details.fixedAmount) {
              this.setState({
                discountLoading: false,
                //   price: subscriptionPackage.price - details.discountAmount,
                price: subscriptionPackage.price,
                discountAmount: details.discountAmount,
                fixedAmount: true,
                discountCodeApplied: true,
                discountError: null,
              });
            } else {
              this.setState({
                discountLoading: false,
                price:
                  subscriptionPackage.price -
                  (subscriptionPackage.price * details.discountAmount) / 100,
                discountAmount: subscriptionPackage.price,
                discountCodeApplied: true,
                discountError: null,
              });
            }
          }
        },
        error: (code, message) => {
          this.setState({
            discountLoading: false,
            discountError: message,
          });
        },
      },
    );
  };

  subscribeUser = (e) => {
    e.preventDefault();

    this.setState({
      purchaseLoading: true,
    });

    const { discountCodeApplied, discountCode, discountAmount, gatewayId } =
      this.state;
    const { subscriptionPackage, onDone } = this.props;

    const username = localStorage.getItem("PROFILE_USERNAME");

    let source = "direct";

    let medium = null;

    let campaign = null;

    let referrer = null;

    if (localStorage.getItem("utm_source")) {
      source = localStorage.getItem("utm_source");
    }

    if (localStorage.getItem("utm_medium")) {
      medium = localStorage.getItem("utm_medium");
    }

    if (localStorage.getItem("utm_campaign")) {
      campaign = localStorage.getItem("utm_campaign");
    }

    if (localStorage.getItem("referrer")) {
      referrer = localStorage.getItem("referrer");
    }

    Api().subscriptionService.subscribeUser(
      subscriptionPackage.id,
      false,
      discountCodeApplied ? discountCode : null,
      source,
      medium,
      campaign,
      referrer,
      {
        success: (response) => {
          if (response.status) {
            if (response.status >= 200 && response.status < 300) {
              alert("پرداخت شما با موفقیت انجام شد");
              const { history } = this.props;
              history.push("/account");
              // TODO: Improve this dialog
              onDone();
            } else if (response.status === 402) {
              const userId = localStorage.getItem("userId");
              const extraCallbackParam = {
                event: "purchase",
                user_id: userId,
                phoneNumber: username,
                ecommerce: {
                  items: [
                    {
                      item_id: subscriptionPackage.id,
                      item_name: subscriptionPackage.displayName,
                      coupon: discountCodeApplied ? discountCode : "",
                      discount: discountAmount,
                      price: subscriptionPackage.price,
                    },
                  ],
                },
              };

              Api().paymentService.requestPayment(
                response.data.order.id,
                response.data.order.amount,
                base64.encode(utf8.encode(JSON.stringify(extraCallbackParam))),
                gatewayId,
                {
                  success: (data) => {
                    Api().paymentService.startPayment(data.paymentToken, {
                      success: (data) => {
                        this.setState({
                          gatewayRedirect: data,
                          purchaseLoading: false,
                        });

                        document.farazpay.submit();
                        onDone();
                      },
                      error: () => {
                        // TODO: handle error
                        this.setState({
                          purchaseLoading: false,
                        });
                      },
                    });
                  },
                  error: () => {
                    // TODO: Handle error
                    this.setState({
                      purchaseLoading: false,
                    });
                  },
                },
              );
            } else if (response.status === 401) {
              const { onUserIsNotAuthenticated } = this.props;
              if (onUserIsNotAuthenticated) {
                onUserIsNotAuthenticated();
                this.setState({ purchaseLoading: false });
              }
            } else {
              toast.error(
                `خطای شماره ${response.status} لطفا با پشتیبانی آی اپس تماس بگیرید`,
              );
              this.setState({
                purchaseLoading: false,
              });
            }
          }
        },
        error: (code) => {
          // TODO: Handle error
          this.setState({
            purchaseLoading: false,
          });
        },
      },
    );
  };

  formatPrice(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    const { subscriptionPackage, v2, packageIndex, onDone } = this.props;
    const {
      price,
      showDiscountField,
      discountLoading,
      purchaseLoading,
      discountCode,
      discountError,
      discountCodeApplied,
      gatewayRedirect,
      fixedAmount,
      discountAmount,
      gatewayId,
    } = this.state;

    let colors = {
      0: ["#A4FFF9", "#1B8AF4"],
      1: ["#E5DCFF", "#1B8AF4"],
      2: ["#B8DCFF", "#1B8AF4"],
    };
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      colors = {
        0: ["#9BF9FF", "#000"],
        1: ["#AA8CFF", "#000"],
        2: ["#3AACFF", "#000"],
      };
    }

    const packageIndexClass = subscriptionPackage.colorType
      ? `package-${subscriptionPackage.colorType}`
      : `package-1`;
    const view = (
      <div className="payment-options">
        <div
          id="payment-options-details"
          className={classNames("payment-options-details", {
            "show-discount": showDiscountField,
          })}
        >
          <div className="back-button-wrapper">
            {v2 ? (
              <a
                href="#"
                className="back-button"
                onClick={(e) => {
                  e.preventDefault();
                  onDone();
                }}
              >
                {" "}
              </a>
            ) : (
              ""
            )}
          </div>
          {v2 ? (
            <div className="packages-list">
              <div className="subscription-package">
                <div
                  className={classNames("content", "package")}
                  style={{
                    backgroundColor: colors[packageIndex][0],
                    padding: "8px 15px",
                  }}
                >
                  <div className="package-header-container">
                    <div className="package-header">
                      <div className="detail">
                        <div className="price-title">
                          <h3>{subscriptionPackage.displayName}</h3>
                          <p className={classNames("originalPrice", "price")}>
                            {parseInt(
                              ((subscriptionPackage.originalPrice -
                                subscriptionPackage.price) /
                                subscriptionPackage.originalPrice) *
                                100,
                            )}
                            {"% تخفیف"}
                          </p>
                        </div>
                        <div className="prices">
                          <p
                            className="price"
                            style={{ color: colors[packageIndex][1] }}
                          >
                            {parseInt(
                              (subscriptionPackage.price /
                                10000 /
                                (subscriptionPackage.durability - 4)) *
                                30,
                            )}{" "}
                            <span style={{ color: colors[packageIndex][1] }}>
                              هزار تومان ماهانه
                            </span>
                          </p>
                          <p
                            style={{
                              color: "#000",
                              paddingBottom: 0,
                            }}
                          >
                            قیمت نهایی:{" "}
                            <strong style={{ color: "#000" }}>
                              {parseInt(subscriptionPackage.price / 10000)} هزار
                              تومان
                            </strong>
                          </p>
                        </div>
                      </div>

                      {subscriptionPackage.originalPrice && (
                        <div className="prices"></div>
                      )}
                    </div>
                  </div>
                  {/*

            <div className="packages-list">
              <div className="subscription-package">
                <div
                  className={classNames("content", "package")}
                  style={{
                    backgroundColor: colors[packageIndex][0],
                  }}
                >
                  <div className="package-header-container">
                    <div className="package-header">
                      <div className="detail">
                        <h3>{subscriptionPackage.displayName}</h3>
                        <p className="description">
                          {subscriptionPackage.description || ""}
                        </p>
                        {reward[subscriptionPackage.id] && (
                          <span className="reward">
                            <img
                              src={`/images/${
                                reward[subscriptionPackage.id].logo
                              }`}
                              alt={reward[subscriptionPackage.id].title}
                            />
                            <p className="title">
                              {reward[subscriptionPackage.id].title}
                            </p>
                          </span>
                        )}
                      </div>

                      <div className="prices">
                        <p className={classNames("originalPrice", "price")}>
                          {subscriptionPackage.originalPrice / 10000}
                        </p>
                        <p className="price">
                          {subscriptionPackage.price / 10000}
                        </p>
                        <p className="currency">هزار تومان</p>
                      </div>
                    </div>
                  </div>
                  */}

                  {/*{priceAfterVoucherApplied ? <p className="voucherPrice">{priceAfterVoucherApplied / 10} <span>تومان</span></p> : ''}*/}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="alerts">
            {subscriptionPackage.additionalDescription ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: subscriptionPackage.additionalDescription,
                }}
              />
            ) : (
              ""
            )}
            <p>
              - <b>اشتراک تهیه شده</b> فقط بر روی <b>یک دستگاه</b> قابل نصب است.
            </p>
            <p>
              - <b>این اشتراک</b> قابل انتقال به شماره یا <b>دستگاه</b> دیگری
              نمی‌باشد.
            </p>
            <p>- فعال‌سازی اشتراک ممکن است تا ۷۲ ساعت زمان‌بر باشد.</p>
            <p>
              - <b>اشتراک تهیه شده</b> شامل <b>پرداخت درون‌برنامه‌ای</b>{" "}
              نمی‌باشد.
            </p>
            {[16, 17].includes(subscriptionPackage.id) && (
              <p>- کد هدیه بعد از تهیه اشتراک برای شما پیامک می‌گردد.</p>
            )}
          </div>
          {subscriptionPackage.id !== 11 ? (
            <div className={classNames("discount", { error: discountError })}>
              {showDiscountField ? (
                <div className="discount-field">
                  {!discountCodeApplied ? (
                    <>
                      <p className="title">
                        {discountError ? discountError : "استفاده از کد تخفیف"}
                      </p>
                      <form onSubmit={this.applyDiscount}>
                        <input
                          type="text"
                          onChange={(e) => {
                            this.setState({
                              discountCode: toEnglishDigits(e.target.value),
                            });
                          }}
                          value={discountCode}
                        />
                        {/*<button type="submit">اعمال کد</button>*/}
                        <Button
                          className={
                            discountCode && discountCode.length > 2
                              ? "active"
                              : ""
                          }
                          type="submit"
                          onClick={this.applyDiscount}
                        >
                          {discountLoading ? (
                            <PulseLoader
                              css={{ "text-align": "center" }}
                              color="#fff"
                              size="6"
                            />
                          ) : (
                            "اعمال کد"
                          )}
                        </Button>
                      </form>
                    </>
                  ) : (
                    <div className="success">
                      <p>کد تخفیف با موفقیت ثبت شد</p>
                    </div>
                  )}
                </div>
              ) : (
                <a
                  href="#"
                  id="show-discount-field"
                  onClick={this.showDiscountField}
                >
                  <div className="description">
                    <p className="title">استفاده از کد تخفیف</p>
                    <p className="subtitle">
                      در صورت داشتن کد تخفیف کد را وارد نمایید
                    </p>
                  </div>
                  <div className="image">
                    <img src="/images/add-icon@3x.png" alt="" />
                  </div>
                </a>
              )}
            </div>
          ) : (
            ""
          )}
          {
            //gate ways
            <div className="gateway-list">
              <h4>روش و درگاه پرداخت</h4>
              {gateways.map((gateway, index) => {
                return (
                  <button
                    className={gateway.id === gatewayId ? "active" : ""}
                    onClick={() => {
                      this.setState({ gatewayId: gateway.id });
                    }}
                  >
                    <div className="gateway" key={index}>
                      <img src={gateway.logo} alt={gateway.title} />
                      <div className="gateway-info">
                        <h6>{gateway.title}</h6>
                        {gateway.description ? (
                          <p>{gateway.description}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </button>
                );
              })}
            </div>
          }
          {!v2 ? (
            <div className="progress">
              <div className="values">
                <p className="total">
                  {subscriptionPackage
                    ? `${subscriptionPackage.price / 10} تومان`
                    : 0}
                </p>
                <p className="final">
                  {price / 10} {"تومان"}
                </p>
              </div>
              <LineProgressBar
                percent={
                  subscriptionPackage
                    ? (price / subscriptionPackage.price) * 100
                    : 0
                }
                strokeColor="rgb(2, 122, 255)"
                strokeWidth={2}
                trailColor="#f2f2f8"
                trailWidth={2}
              />
            </div>
          ) : (
            ""
          )}
          {gatewayRedirect ? (
            <div className="gateway-redirection">
              <div dangerouslySetInnerHTML={{ __html: gatewayRedirect }} />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="actions">
          {v2 && discountCodeApplied && fixedAmount === false ? (
            <div className="prices">
              <p className="key ">هزینه بسته همراه با تخفیف :</p>
              <p className="value normal-weight">
                {this.formatPrice(price / 10)} تومان
              </p>
            </div>
          ) : (
            <div className="prices">
              <p className="key ">بسته {subscriptionPackage.displayName}:</p>
              <p className="value normal-weight">
                {this.formatPrice(price / 10)} تومان
              </p>
            </div>
          )}
          {/*<div
            className={`prices ${(v2 && discountCodeApplied && fixedAmount === false) ||
              !(v2 && discountCodeApplied)
              ? "prices-divider"
              : ""
              }`}
          >
            <p className="key">مالیات بر ارزش افزوده:</p>
            <p className="value normal-weight">
              {this.formatPrice((price * 0.1) / 10)} تومان
            </p>
          </div>*/}
          {v2 && discountCodeApplied && fixedAmount ? (
            <div className="prices prices-divider">
              <p className="key">مبلغ تخفیف :</p>
              <p className="value normal-weight">
                {this.formatPrice(discountAmount / 10)} تومان
              </p>
            </div>
          ) : null}
          {/*<div className="prices">
            <p className="key">مبلغ قابل پرداخت:</p>
            <p className="value">
              {this.formatPrice(
                v2 && discountCodeApplied && fixedAmount
                  ? (price + price * 0.1 - discountAmount) / 10
                  : (price + price * 0.1) / 10
              )}{" "}
              تومان
            </p>
          </div>*/}
          <button
            id="purchase-button"
            className="button"
            onClick={this.subscribeUser}
          >
            {purchaseLoading ? (
              <PulseLoader
                css={{ "text-align": "center" }}
                color="#fff"
                size="8"
              />
            ) : (
              `پرداخت`
            )}
          </button>
        </div>
      </div>
    );

    return view;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleLoginView: (isLoginRequired) =>
      dispatch({ type: "LOGIN_REQUIRED", isLoginRequired }),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(PaymentOptions));
